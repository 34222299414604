import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>ethscri.be</p>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/punks" element={<Punks />} />
        </Routes>
      </div>
    </Router>
  );
}

const Home = () => (
  <div>
    <h1>Welcome to ethscri.be</h1>
  </div>
);

const Punks = () => (
  <iframe src="https://transformativepunks.netlify.app/" style={{ width: '100%', height: '100vh', border: 'none' }} title="Transformative Punks"></iframe>
);

export default App;